<script setup lang="ts">
definePageMeta({
   layer: 'contributions',
})

/**
 * Redirect to the tutorial page if the user hasn't seen it yet.
 */
const { shouldRedirect } = useTutorial()

if (shouldRedirect.value) {
   const localeRoute = useLocaleRoute()
   await navigateTo(localeRoute('/get-started'))
}

/**
 * Translation.
 */
const { t } = useI18n()

/**
 * Indicators.
 */
const { indicators } = useDisplayedIndicator()

/**
 * Fetch global stats.
 */
const { data } = await useCachedFetch('/api/global', {
   key: 'global-stats',
})
</script>

<template>
   <div class="flex flex-col gap-8 bg-gradient-to-bl from-blue-dark to-blue text-white">
      <div class="flex flex-col gap-4 text-pretty">
         <h3 class="text-2xl font-medium md:text-3xl xl:text-4xl">
            {{ t('panel.stats.title') }}
         </h3>

         <div
            class="flex flex-wrap items-center gap-4"
         >
            <p>
               <strong>{{ formatValue(data?.contributions) }}</strong>
               {{ t('global.contributions') }}
            </p>

            <p>
               <strong>{{ formatValue(data?.contributors) }}</strong>
               {{ t('global.contributors') }}
            </p>
         </div>
      </div>

      <div
         v-if="data"
         class="my-4 mb-auto grid place-items-start gap-8 text-balance @xl:grid-cols-2 @xl:gap-12"
      >
         <PanelBarStats
            v-for="(indicator, index) of indicators"
            :key="index"
            :title="t(`${indicator}.information.title`)"
            :subtitle="t(`${indicator}.description`)"
            :description="t(`${indicator}.information.description`)"
            :footer="t(`${indicator}.information.sources`)"
            :value="data.values[indicator]?.value"
            :max="data.values[indicator]?.max"
            :total="t(`${indicator}.total-potential`)"
         >
            {{ t(`${indicator}.title`) }}
         </PanelBarStats>
      </div>

      <div class="gris-cols-1 my-12 grid gap-2 @container lg:grid-cols-2 xl:grid-cols-3">
         <PanelLinkBlock
            :to="{ name: 'countries' }"
            :title="t('panel.search-data.title')"
            picture="/images/search-data.png"
         />
         <PanelLinkBlock
            :to="{ name: 'contributors' }"
            :title="t('panel.search-contributor.title')"
            picture="/images/search-contributor.png"
         />
         <PanelLinkBlock
            :to="{ name: 'contributions' }"
            :title="t('panel.search-contribution.title')"
            picture="/images/search-contribution.png"
         />
      </div>

      <ClientOnly>
         <!-- This is for the Getting started modal -->
         <NuxtPage />
      </ClientOnly>
   </div>
</template>
